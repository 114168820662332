import { browserLocalPersistence, GoogleAuthProvider, onAuthStateChanged, setPersistence, signInWithPopup, signOut } from "firebase/auth";
import { Button } from "../../components/custom-button/Button"
// import { Input } from "../../components/input/input"
import { auth, db } from "../../firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

export default function OnboardingPage() {

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();

        try {
            await setPersistence(auth, browserLocalPersistence);
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            if (!user?.email) {
                alert("Unable to retrieve email. Please try again.");
                return;
            }

            const isNaoMedicalAccount = user.email.endsWith("@naomedical.com");
            const userRef = doc(db, "users", user.uid);
            const userDoc = await getDoc(userRef);

            if (userDoc.exists()) {
                // Existing user
                const existingUser = userDoc.data();

                if (existingUser.usage === undefined) {
                    await updateDoc(userRef, {
                        usage: 0,
                        updatedAt: new Date(),
                    });
                    console.log("Usage field initialized for existing user.");
                }

                if (isNaoMedicalAccount && existingUser.user_type === "FREE") {
                    await updateDoc(userRef, {
                        user_type: "PREMIUM",
                        updatedAt: new Date(),
                    });
                }
            } else {
                // New user
                const userType = isNaoMedicalAccount ? "PREMIUM" : "FREE";
                await setDoc(userRef, {
                    email: user.email,
                    user_type: userType,
                    time: 300,
                    usage: 0,
                    createdAt: new Date(),
                });
                // console.log(`New user created with ${userType} access.`);
            }
        } catch (error) {
            console.error("Error during sign-in:", error);
            alert("An error occurred during sign-in. Please try again.");
        }
    };

    // Optional: Handle auth state changes
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // console.log(`User logged in: ${user.email}`);
        }
    });

    return (
        <div className="h-[100vh] flex flex-col md:flex-row overflow-hidden bg-light-blue">
            {/* Left Section */}
            <div className="w-full md:w-1/2 flex flex-col relative order-2 md:order-1 h-full">
                <h1 className="text-[#08373E] text-[30px] w-[90%] md:text-[38px] font-bold leading-tight gap-2 m-0 p-0 absolute left-14 pr-16 md:left-0 md:top-[10%] md:ml-[13%] md:mr-[13%] flex justify-center items-center">
                    <img src="/star.svg" alt="harmoni" className="absolute top-0 left-[-10%]" />
                    Transform Communication with Our Medical Translation Service
                    <img src="/star-right.svg" alt="harmoni" className="absolute right-8 md:right-10 bottom-2" />
                </h1>

                {/* Phone Mockup */}
                <div className="relative flex-1 flex items-center justify-center">
                    <img src="/onboard.png" alt="nao-medical" className="absolute z-10 bottom-0 md:h-auto md:w-[80%] h-auto w-[80%]" />
                </div>
            </div>

            {/* Right Section */}
            <div className="w-full md:w-1/2 p-6 md:p-12 flex items-center bg-white my-10 mr-5 h-full overflow-y-auto md:order-2 order-1">
                <div className="max-w-md w-full mx-auto">
                    <h2 className="text-[#0D5C67] text-[24px] md:text-[30px] font-less-bold mb-2">Welcome to Harmoni</h2>
                    <p className="text-gray-600 text-[15px] md:text-[17px] mb-8">
                        {/* Enjoy 10 free minutes of our Medical Translation Service. Let's get started! */}
                        Sign in with your work email. Let's get started!
                    </p>

                    <div className="space-y-4">
                        {/* <div className="mb-8">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                                Email
                            </label>
                            <Input
                                id="email"
                                type="email"
                                placeholder="Enter your email address"
                                className="w-full"
                            />
                        </div>

                        <Button className="w-full bg-[#0D5C67] hover:bg-[#083638] text-white flex items-center justify-center gap-2 rounded-[24px]">
                            <img src="/white-star.svg" alt="harmoni" className="h-5 w-5" />
                            Sign Up
                            <img src="/white-star-right.svg" alt="harmoni" className="h-5 w-5" />
                        </Button> */}

                        <Button
                            variant="outline"
                            onClick={handleGoogleSignIn}
                            className="w-full flex items-center justify-center gap-2 rounded-[24px] border-[#0D5C67] hover:bg-light-blue"
                        >
                            <svg className="w-5 h-5" viewBox="0 0 24 24">
                                <path
                                    d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                                    fill="#4285F4"
                                />
                                <path
                                    d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                                    fill="#34A853"
                                />
                                <path
                                    d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                                    fill="#FBBC05"
                                />
                                <path
                                    d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                                    fill="#EA4335"
                                />
                            </svg>
                            Continue With Work Email
                        </Button>
                        {/* <Button
                            variant="outline"
                            className="w-full flex items-center justify-center gap-2 rounded-[24px] border-[#0D5C67] hover:bg-light-blue"
                        >
                            <svg className="w-5 h-5" viewBox="0 0 23 23">
                                <path
                                    fill="#f35325"
                                    d="M1 1h10v10H1z"
                                />
                                <path
                                    fill="#81bc06"
                                    d="M12 1h10v10H12z"
                                />
                                <path
                                    fill="#05a6f0"
                                    d="M1 12h10v10H1z"
                                />
                                <path
                                    fill="#ffba08"
                                    d="M12 12h10v10H12z"
                                />
                            </svg>
                            Sign Up With Microsoft
                        </Button> */}

                        {/* <p className="text-center text-[12px] md:text-[14px] text-gray-500">
                            Create a free account, no credit card required
                        </p> */}

                        <p className="text-center text-sm">
                            Need premium? Contact us at{" "}
                            <a href="#" className="text-[#0D5C67]">harmoni@harmonitranslate.com</a>
                        </p>

                        {/* <p className="text-center text-sm">
                            Already have an account?{" "}
                            <a href="#" className="text-[#0D5C67]">Sign In</a>
                        </p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

