import { ripples } from 'ldrs';

ripples.register();

export function MicButton({
    isConnected,
    onStart,
    onStop,
    buttonStyle = 'regular',
    showRipple = false,
    disabled,
    ...rest
}) {
    const handleClick = () => {
        if (isConnected) {
            onStop(); // Call stop function
        } else {
            onStart(); // Call start function
        }
    };

    return (
        <button
            className={`relative flex justify-center items-center cursor-pointer button-style-${buttonStyle}`}
            onClick={handleClick}
            disabled={disabled}
            {...rest}
        >
            <img
                src="/sess-button.svg"
                alt="Session Button"
                width={70}
                height={70}
            />
            {showRipple ? (
                <div className="absolute mt-[6px]">
                    <l-ripples size="80" speed="2" color="red"></l-ripples>
                </div>
            ) : (
                <img
                    src="/mic-fill.svg"
                    alt="Microphone Icon"
                    width={55}
                    height={55}
                    className="absolute"
                />
            )}
        </button>
    );
}
