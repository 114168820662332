import { useEffect, useState } from "react";
import { ChevronDown } from "react-feather";
// import languagesSource from "../../data/languages.json"
import languagesTarget from "../../data/languages.json"


const LangSelect = ({ sourceLang, setSourceLang, targetLang, setTargetLang }: any) => {
    const [isOpenSource, setIsOpenSource] = useState(false);
    const [isOpenTarget, setIsOpenTarget] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const filteredLanguages = languagesTarget.filter((lang) =>
        lang.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const handleSourceLangSelect = (code: string) => {
        setSourceLang(code);
        setIsOpenSource(false);
    };

    const handleTargetLangSelect = (name: string) => {
        setTargetLang(name);
        setIsOpenTarget(false);
    };

    const getFlagByCode = (value: string, list: any[]) => {
        const lang = list.find((lang) => lang.name === value);
        return lang ? lang.flag : "/flags/default.svg"; // Provide a fallback if not found
    };

    const handleToggleSource = () => {
        setIsOpenSource((prev) => !prev);
        setIsOpenTarget(false); // Close the target dropdown if open
    };

    // Handle clicks to toggle the target dropdown
    const handleToggleTarget = () => {
        setIsOpenTarget((prev) => !prev);
        setIsOpenSource(false); // Close the source dropdown if open
    };

    // Close dropdowns when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest(".language-select")) {
                setIsOpenSource(false);
                setIsOpenTarget(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="flex flex-row justify-between items-center main-container h-[47px] bg-[rgba(255,255,255,0.026)] rounded-[50px] relative shadow-[0_1px_3px_0_rgba(0,0,0,0.25)] mx-auto my-7">
            {/* Source Language Select */}
            {/* <div className="relative language-select">
                <button onClick={handleToggleSource} className="flex items-center gap-1 cursor-pointer w-[160px] ml-4">
                    <img
                        src={getFlagByCode(sourceLang, languagesSource)}
                        alt="Source Flag"
                        width={14}
                        height={14}
                    />
                    <span className="text-[16px] font-medium text-black">
                        {languagesSource.find((lang) => lang.code === sourceLang)?.name}
                    </span>
                    <ChevronDown color="black" size={12} />
                </button>

                {isOpenSource && (
                    <div className="absolute top-full mt-1 bg-white border border-gray-200 border-t-0 shadow-lg rounded-br-[13.5px] rounded-bl-[13.5px] w-[150px] z-10">
                        <ul className="py-1">
                            {languagesSource.map((lang) => (
                                <li
                                    key={lang.code}
                                    className="flex items-center gap-1 px-4 py-2 text-[18px] text-gray-800 cursor-pointer hover:bg-gray-100"
                                    onClick={() => handleSourceLangSelect(lang.code)}
                                >
                                    <img src={lang.flag} alt={`${lang.name} Flag`} width={14} height={14} />
                                    {lang.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div> */}

            {/* Swap Languages */}
            {/* <div className="w-full flex justify-start items-center cursor-pointer" onClick={handleSwapLanguages}>
                <ArrowLeftRight color="black" size={15} />
            </div> */}

            {/* Target Language Select */}
            <div className="relative language-select">
                <button
                    onClick={handleToggleTarget}
                    className="flex items-center justify-center gap-1 cursor-pointer w-full mr-9"
                >
                    <img
                        src={getFlagByCode(targetLang, languagesTarget)}
                        alt="Target Flag"
                        width={14}
                        height={14}
                    />
                    <span className="text-[16px] font-medium text-black">
                        {targetLang || "English"}
                    </span>
                    <ChevronDown color="black" size={12} />
                </button>

                {isOpenTarget && (
                    <div className="absolute top-full mt-1 bg-white border border-gray-200 border-t-0 shadow-lg rounded-br-[13.5px] rounded-bl-[13.5px] w-[200px] z-10">
                        {/* Search Box */}
                        <div className="p-2">
                            <input
                                type="text"
                                placeholder="Search language..."
                                className="w-full px-2 py-1 border rounded text-sm"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>

                        {/* Language List */}
                        <ul className="py-1 max-h-[200px] overflow-y-auto">
                            {filteredLanguages.map((lang) => (
                                <li
                                    key={lang.name} // Use name as the key
                                    className="flex items-center gap-1 px-4 py-2 text-sm text-gray-800 cursor-pointer hover:bg-gray-100 text-[16px]"
                                    onClick={() => handleTargetLangSelect(lang.name)} // Pass name
                                >
                                    <img src={lang.flag} width={14} height={14} />
                                    {lang.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LangSelect;
