import React, { createContext, useContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../firebase";

interface UserContextType {
  loading: any;
  isAuth: any;
  user: any;
  setUser: React.Dispatch<React.SetStateAction<any>>;
  sessionId: any;
  setSessionId: React.Dispatch<React.SetStateAction<any>>;
}

const UserContext = createContext<UserContextType | null>(null);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuth, setIsAuth] = useState<any>(() => {
    const storedAuth = sessionStorage.getItem("isAuth");
    return storedAuth ? JSON.parse(storedAuth) : null;
  });
  const [user, setUser] = useState<any>(() => {
    const storedUser = sessionStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [sessionId, setSessionId] = useState<any>(() => {
    const storedSession = sessionStorage.getItem("session");
    return storedSession ? JSON.parse(storedSession) : null;
  });
  useEffect(() => {
    if (sessionId) {
      sessionStorage.setItem("session", JSON.stringify(sessionId));
    } else {
      sessionStorage.removeItem("session");
    }
  }, [sessionId]);


  useEffect(() => {
    if (isAuth) {
      sessionStorage.setItem("isAuth", JSON.stringify(isAuth));
    } else {
      sessionStorage.removeItem("isAuth");
    }
  }, [isAuth]);

  // Sync `user` with sessionStorage
  useEffect(() => {
    if (user) {
      sessionStorage.setItem("user", JSON.stringify(user));
    } else {
      sessionStorage.removeItem("user");
    }
  }, [user]);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setIsAuth(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        const userDocRef = doc(db, "users", authUser.uid);

        const unsubscribeSnapshot = onSnapshot(userDocRef, (snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.data();
            setUser(userData);

            sessionStorage.setItem("user", JSON.stringify(userData));
          } else {
            setUser(null);
            sessionStorage.removeItem("user");
          }
        });

        return () => unsubscribeSnapshot();
      } else {
        setUser(null);
        sessionStorage.removeItem("user");
      }
    });

    return () => unsubscribeAuth();
  }, []);

  return (
    <UserContext.Provider value={{ loading, isAuth, user, setUser, sessionId, setSessionId }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
