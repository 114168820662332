"use client";
import axios from 'axios';

const cleanString = (str) => {
    return str.replace(/^"(.*)"$/, '$1');
};

const translateText = async (
    text,
    targetLang
) => {
    const prompt = `
Translate the following text from English to ${targetLang} or from ${targetLang} to English.

- **Only provide the translated text** without any extra words, explanations, or additional commentary.

Text to translate:
"${text}"
`;
    try {
        const response = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
                model: 'gpt-3.5-turbo',
                messages: [{ role: 'user', content: prompt }],
                max_tokens: 1000,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                },
            }
        );
        const cleanedString = cleanString(response.data.choices[0].message.content);
        return cleanedString;
    } catch (error) {
        throw error;
    }
};

export default translateText;
