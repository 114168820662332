import { cardio } from 'ldrs'

cardio.register()

const AppLoader = () => {
    return (
        <div className='h-screen w-full flex justify-center items-center'>
            <l-cardio
                size="100"
                stroke="4"
                speed="1"
                color="#0D5C67"
            ></l-cardio>
        </div>
    )
}

export default AppLoader